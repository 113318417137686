const CorporateStep3Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 34.6282C15.2163 34.2978 13.8233 33.7535 12.5332 33.0132M23.3332 5.37158C26.6468 6.12837 29.6053 7.98773 31.7243 10.6453C33.8433 13.3028 34.9973 16.601 34.9973 19.9999C34.9973 23.3988 33.8433 26.697 31.7243 29.3546C29.6053 32.0121 26.6468 33.8715 23.3332 34.6282M7.63154 28.4882C6.72336 27.1668 6.03293 25.7083 5.58654 24.1682M5.20654 17.4999C5.47321 15.9166 5.98654 14.4166 6.70654 13.0416L6.98821 12.5332M11.5115 7.63158C13.0719 6.55971 14.821 5.79288 16.6665 5.37158"
        stroke="#2A5FAC"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 19.9998L18.3333 23.3332L25 16.6665"
        stroke="#2A5FAC"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CorporateStep3Icon;
