const SchoolsStep3Icon = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3343 6.66669H24.001C30.2864 6.66669 33.4291 6.66669 35.3817 8.61931C36.7876 10.0252 37.1812 12.0481 37.2914 15.4167H4.04384C4.15405 12.0481 4.5477 10.0252 5.9536 8.61931C7.90622 6.66669 11.0489 6.66669 17.3343 6.66669Z"
        fill="#2A5FAC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.3335 21.3626L36.6526 20.6817C35.1882 19.2172 32.8138 19.2172 31.3493 20.6817L30.0136 22.0174C29.4806 20.5955 28.109 19.5834 26.501 19.5834C24.4299 19.5834 22.751 21.2623 22.751 23.3334V26.2731C21.9345 26.3639 21.1421 26.7223 20.516 27.3484C19.0515 28.8128 19.0515 31.1872 20.516 32.6517L21.1977 33.3334H17.3343C11.0489 33.3334 7.90622 33.3334 5.9536 31.3807C4.00098 29.4281 4.00098 26.2854 4.00098 20C4.00098 19.2635 4.00098 18.5701 4.00412 17.9167H37.3312C37.3343 18.5701 37.3343 19.2635 37.3343 20C37.3343 20.4716 37.3343 20.9255 37.3335 21.3626ZM10.6676 25.4167C9.97729 25.4167 9.41764 25.9763 9.41764 26.6667C9.41764 27.357 9.97729 27.9167 10.6676 27.9167H17.3343C18.0247 27.9167 18.5843 27.357 18.5843 26.6667C18.5843 25.9763 18.0247 25.4167 17.3343 25.4167H10.6676Z"
        fill="#2A5FAC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.501 22.0834C27.1913 22.0834 27.751 22.643 27.751 23.3334V30.3156L28.9504 29.1161C29.4386 28.628 30.23 28.628 30.7182 29.1161C31.2063 29.6043 31.2063 30.3957 30.7182 30.8839L27.3849 34.2172C26.8967 34.7054 26.1052 34.7054 25.6171 34.2172L22.2838 30.8839C21.7956 30.3957 21.7956 29.6043 22.2838 29.1161C22.7719 28.628 23.5634 28.628 24.0515 29.1161L25.251 30.3156V23.3334C25.251 22.643 25.8106 22.0834 26.501 22.0834ZM33.1171 22.4495C33.6052 21.9613 34.3967 21.9613 34.8849 22.4495L38.2182 25.7828C38.7063 26.271 38.7063 27.0624 38.2182 27.5506C37.73 28.0387 36.9386 28.0387 36.4504 27.5506L35.251 26.3511V33.3334C35.251 34.0237 34.6913 34.5834 34.001 34.5834C33.3106 34.5834 32.751 34.0237 32.751 33.3334V26.3511L31.5515 27.5506C31.0634 28.0387 30.2719 28.0387 29.7838 27.5506C29.2956 27.0624 29.2956 26.271 29.7838 25.7828L33.1171 22.4495Z"
        fill="#2A5FAC"
      />
    </svg>
  );
};

export default SchoolsStep3Icon;
