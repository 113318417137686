const PlusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3999 11.3999V6.3999C13.3999 6.13469 13.2945 5.88033 13.107 5.6928C12.9195 5.50526 12.6651 5.3999 12.3999 5.3999C12.1347 5.3999 11.8803 5.50526 11.6928 5.6928C11.5053 5.88033 11.3999 6.13469 11.3999 6.3999V11.3999H6.3999C6.13469 11.3999 5.88033 11.5053 5.6928 11.6928C5.50526 11.8803 5.3999 12.1347 5.3999 12.3999C5.3999 12.6651 5.50526 12.9195 5.6928 13.107C5.88033 13.2945 6.13469 13.3999 6.3999 13.3999H11.3999V18.3999C11.3999 18.6651 11.5053 18.9195 11.6928 19.107C11.8803 19.2945 12.1347 19.3999 12.3999 19.3999C12.6651 19.3999 12.9195 19.2945 13.107 19.107C13.2945 18.9195 13.3999 18.6651 13.3999 18.3999V13.3999H18.3999C18.6651 13.3999 18.9195 13.2945 19.107 13.107C19.2945 12.9195 19.3999 12.6651 19.3999 12.3999C19.3999 12.1347 19.2945 11.8803 19.107 11.6928C18.9195 11.5053 18.6651 11.3999 18.3999 11.3999H13.3999Z"
        fill="#16325B"
      />
    </svg>
  );
};

export default PlusIcon;
