import { FC, useState } from 'react';
import { HowWorksProps } from '@kronoss/bricks/HomePage/HowWorks/HowWorks.props';
import styles from './HowWorks.module.scss';
import { Container } from '@mui/material';
import cn from 'classnames';
import CurlyArrowUpIcon from '@kronoss/icons/CurlyArrowUpIcon';
import CurlyArrowDownIcon from '@kronoss/icons/CurlyArrowDownIcon';
import useTranslation from 'next-translate/useTranslation';
import Grid from '@mui/material/Grid2';
import SchoolsStep1Icon from '@kronoss/icons/howWorks/schools/SchoolsStep1Icon';
import SchoolsStep2Icon from '@kronoss/icons/howWorks/schools/SchoolsStep2Icon';
import SchoolsStep3Icon from '@kronoss/icons/howWorks/schools/SchoolsStep3Icon';
import TeachersStep1Icon from '@kronoss/icons/howWorks/teachers/TeachersStep1Icon';
import TeachersStep2Icon from '@kronoss/icons/howWorks/teachers/TeachersStep2Icon';
import TeachersStep3Icon from '@kronoss/icons/howWorks/teachers/TeachersStep3Icon';
import StudentsStep1Icon from '@kronoss/icons/howWorks/students/StudentsStep1Icon';
import StudentsStep2Icon from '@kronoss/icons/howWorks/students/StudentsStep2Icon';
import StudentsStep3Icon from '@kronoss/icons/howWorks/students/StudentsStep3Icon';
import ParentsStep1Icon from '@kronoss/icons/howWorks/parents/ParentsStep1Icon';
import ParentsStep2Icon from '@kronoss/icons/howWorks/parents/ParentsStep2Icon';
import ParentsStep3Icon from '@kronoss/icons/howWorks/parents/ParentsStep3Icon';
import CorporateStep1Icon from '@kronoss/icons/howWorks/corporate/CorporateStep1Icon';
import CorporateStep2Icon from '@kronoss/icons/howWorks/corporate/CorporateStep2Icon';
import CorporateStep3Icon from '@kronoss/icons/howWorks/corporate/CorporateStep3Icon';

const HowWorks: FC<HowWorksProps> = () => {
  const { t } = useTranslation('home');
  const howWorkSteps = [
    {
      title: t('howWorks.cards.schools.title'),
      id: 1,
      steps: [
        {
          title: t('howWorks.cards.schools.step1.title'),
          description: t('howWorks.cards.schools.step1.subtitle'),
          icon: <SchoolsStep1Icon />,
        },
        {
          title: t('howWorks.cards.schools.step2.title'),
          description: t('howWorks.cards.schools.step2.subtitle'),
          icon: <SchoolsStep2Icon />,
        },
        {
          title: t('howWorks.cards.schools.step3.title'),
          description: t('howWorks.cards.schools.step3.subtitle'),
          icon: <SchoolsStep3Icon />,
        },
      ],
    },
    {
      title: t('howWorks.cards.teachers.title'),
      id: 2,
      steps: [
        {
          title: t('howWorks.cards.teachers.step1.title'),
          description: t('howWorks.cards.teachers.step3.subtitle'),
          icon: <TeachersStep1Icon />,
        },
        {
          title: t('howWorks.cards.teachers.step2.title'),
          description: t('howWorks.cards.teachers.step2.subtitle'),
          icon: <TeachersStep2Icon />,
        },
        {
          title: t('howWorks.cards.teachers.step3.title'),
          description: t('howWorks.cards.teachers.step3.subtitle'),
          icon: <TeachersStep3Icon />,
        },
      ],
    },
    {
      title: t('howWorks.cards.students.title'),
      id: 3,
      steps: [
        {
          title: t('howWorks.cards.students.step1.title'),
          description: t('howWorks.cards.students.step1.subtitle'),
          icon: <StudentsStep1Icon />,
        },
        {
          title: t('howWorks.cards.students.step2.title'),
          description: t('howWorks.cards.students.step2.subtitle'),
          icon: <StudentsStep2Icon />,
        },
        {
          title: t('howWorks.cards.students.step3.title'),
          description: t('howWorks.cards.students.step3.subtitle'),
          icon: <StudentsStep3Icon />,
        },
      ],
    },
    {
      title: t('howWorks.cards.parents.title'),
      id: 4,
      steps: [
        {
          title: t('howWorks.cards.parents.step1.title'),
          description: t('howWorks.cards.parents.step1.subtitle'),
          icon: <ParentsStep1Icon />,
        },
        {
          title: t('howWorks.cards.parents.step2.title'),
          description: t('howWorks.cards.parents.step2.subtitle'),
          icon: <ParentsStep2Icon />,
        },
        {
          title: t('howWorks.cards.parents.step3.title'),
          description: t('howWorks.cards.parents.step3.subtitle'),
          icon: <ParentsStep3Icon />,
        },
      ],
    },
    {
      title: t('howWorks.cards.corporate.title'),
      id: 5,
      steps: [
        {
          title: t('howWorks.cards.corporate.step1.title'),
          description: t('howWorks.cards.corporate.step1.subtitle'),
          icon: <CorporateStep1Icon />,
        },
        {
          title: t('howWorks.cards.corporate.step2.title'),
          description: t('howWorks.cards.corporate.step2.subtitle'),
          icon: <CorporateStep2Icon />,
        },
        {
          title: t('howWorks.cards.corporate.step3.title'),
          description: t('howWorks.cards.corporate.step3.subtitle'),
          icon: <CorporateStep3Icon />,
        },
      ],
    },
  ];

  const [currentStepId, setCurrentStepId] = useState<number>(
    howWorkSteps[0].id,
  );

  const handleClickCurrentStep = (stepId: number) => {
    stepId !== currentStepId && setCurrentStepId(stepId);
  };

  const currentStep =
    howWorkSteps.find((step) => step.id === currentStepId) ?? howWorkSteps[0];
  return (
    <Container>
      <div className={styles.HowWorks} id={'how-it-works'}>
        <div className={styles.TitleWrapper}>
          <div className={styles.Title}>{t('howWorks.title')}</div>
          <div className={styles.Subtitle}>{t('howWorks.subtitle')}</div>
        </div>
        <div className={styles.HowWorksSteps}>
          <Grid container spacing={0} className={styles.Pills}>
            {howWorkSteps.map((step) => (
              <Grid key={step.id} size={{ md: 'grow', xs: 4 }}>
                <div
                  className={cn(
                    styles.Pill,
                    step.id === currentStepId && styles.Active,
                  )}
                  onClick={() => handleClickCurrentStep(step.id)}
                >
                  {step.title}
                </div>
              </Grid>
            ))}
          </Grid>
          <div className={styles.Steps}>
            {currentStep.steps.map((step, index) => (
              <div className={styles.Step} key={index}>
                <div className={styles.IconsWrapper}>
                  <div className={styles.Illustration}>{step.icon}</div>
                  <div className={styles.NextStepArrow}>
                    {index === 0 && <CurlyArrowUpIcon />}
                    {index === 1 && <CurlyArrowDownIcon />}
                  </div>
                </div>
                <div className={styles.ContentWrapper}>
                  <div className={styles.Title}>{step.title}</div>
                  <div className={styles.Description}>{step.description}</div>
                </div>
                {index < 2 && (
                  <div className={styles.MobileNextStepArrow}>
                    <CurlyArrowDownIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowWorks;
