const CurlyArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="40"
      viewBox="0 0 128 40"
      fill="none"
    >
      <path
        d="M102.829 13.6132C100.428 12.3962 98.5858 13.4158 96.711 13.4487C95.4282 13.4816 94.0468 13.4487 92.8956 13.0211C90.4287 12.1001 90.034 8.54789 92.172 7.13355C93.2574 6.44283 94.5073 5.88365 95.7572 5.58762C103.618 3.7457 111.512 1.93669 119.439 0.226325C120.886 -0.10259 122.498 -0.0368391 124.011 0.193402C126.313 0.522317 127.662 3.055 126.84 5.16006C126.609 5.7521 126.281 6.34413 125.952 6.90328C122.662 12.3304 119.439 17.7904 116.051 23.1517C115.262 24.4016 114.176 25.5857 112.959 26.4738C110.624 28.1841 108.223 27.0658 107.73 24.1385C107.598 23.3162 107.697 22.4281 107.697 21.0138C106.381 21.5071 105.46 21.7703 104.572 22.2307C98.9147 25.1252 93.2903 28.0196 87.6658 30.947C75.1342 37.4266 61.5829 39.6304 47.6697 39.9922C43.328 40.0908 38.8548 39.5975 34.6776 38.4792C28.1322 36.7359 21.7183 34.4664 15.436 31.9995C8.36434 29.2038 3.69374 23.7767 0.700611 16.8694C0.207238 15.7182 -0.121677 14.3039 0.0427803 13.0869C0.174346 12.1988 1.06241 11.0147 1.8847 10.7187C2.70699 10.4227 4.15421 10.8174 4.77915 11.4752C5.83168 12.4949 6.5553 13.9421 7.3118 15.2577C10.4365 20.5862 14.844 24.27 20.6987 26.2435C26.7178 28.2499 32.5725 30.7825 38.9206 31.6048C52.9324 33.381 66.4508 31.2759 79.3772 25.8817C86.416 22.9544 93.0929 19.106 99.9344 15.6524C100.888 15.1591 101.678 14.4355 102.829 13.6132Z"
        fill="#F9DE9A"
      />
    </svg>
  );
};

export default CurlyArrowUpIcon;
