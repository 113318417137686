const ParentsStep3Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_181_33664)">
        <path
          d="M1.6665 1.66675H38.3332M8.33317 1.66675V10.0001C8.33317 15.0001 16.6665 15.3917 16.6665 20.0001C16.6665 24.6084 8.33317 25.0001 8.33317 30.0001V38.3334M31.6665 1.66675V10.0001C31.6665 15.0001 23.3332 15.3917 23.3332 20.0001C23.3332 24.6084 31.6665 25.0001 31.6665 30.0001V38.3334M1.6665 38.3334H38.3332M16.6665 7.50008H23.3332V10.0001C23.3332 11.6667 19.9998 13.3334 19.9998 13.3334C19.9998 13.3334 16.6665 11.6667 16.6665 10.0001V7.50008ZM13.3332 35.0001C13.3332 31.6667 19.9998 28.3334 19.9998 28.3334C19.9998 28.3334 26.6665 31.6667 26.6665 35.0001V38.3334H13.3332V35.0001Z"
          stroke="#2A5FAC"
          stroke-width="4"
        />
      </g>
      <defs>
        <clipPath id="clip0_181_33664">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ParentsStep3Icon;
