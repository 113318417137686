const StudentsStep2Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 8C5 6.67392 5.52678 5.40215 6.46447 4.46447C7.40215 3.52678 8.67392 3 10 3H30C31.3261 3 32.5979 3.52678 33.5355 4.46447C34.4732 5.40215 35 6.67392 35 8V31.3333C35 32.6594 34.4732 33.9312 33.5355 34.8689C32.5979 35.8065 31.3261 36.3333 30 36.3333H10C8.67392 36.3333 7.40215 35.8065 6.46447 34.8689C5.52678 33.9312 5 32.6594 5 31.3333V8ZM13.3333 8V19.6667L17.155 15.845C17.4675 15.5325 17.8914 15.357 18.3333 15.357C18.7753 15.357 19.1991 15.5325 19.5117 15.845L23.3333 19.6667V8C23.3333 7.55797 23.1577 7.13405 22.8452 6.82149C22.5326 6.50893 22.1087 6.33333 21.6667 6.33333H15C14.558 6.33333 14.134 6.50893 13.8215 6.82149C13.5089 7.13405 13.3333 7.55797 13.3333 8Z"
        fill="#D52F6D"
      />
    </svg>
  );
};

export default StudentsStep2Icon;
