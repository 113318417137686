const StudentsStep3Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 34.6284C15.2163 34.2979 13.8233 33.7537 12.5332 33.0134M23.3332 5.3717C26.6468 6.12849 29.6053 7.98786 31.7243 10.6454C33.8433 13.3029 34.9973 16.6011 34.9973 20C34.9973 23.399 33.8433 26.6972 31.7243 29.3547C29.6053 32.0122 26.6468 33.8716 23.3332 34.6284M7.63154 28.4884C6.72336 27.1669 6.03293 25.7084 5.58654 24.1684M5.20654 17.5C5.47321 15.9167 5.98654 14.4167 6.70654 13.0417L6.98821 12.5334M11.5115 7.6317C13.0719 6.55984 14.821 5.793 16.6665 5.3717"
        stroke="#2A5FAC"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StudentsStep3Icon;
