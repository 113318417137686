const ParentsStep1Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0002 6.66659C17.6681 6.66616 15.3767 7.2774 13.3547 8.4393C11.3327 9.6012 9.65079 11.2731 8.47687 13.2882C7.30296 15.3032 6.67808 17.5909 6.66462 19.923C6.65117 22.255 7.2496 24.5498 8.40018 26.5783C9.17786 25.5675 10.1776 24.7492 11.322 24.1866C12.4664 23.6239 13.7249 23.3319 15.0002 23.3333H25.0002C26.2755 23.3319 27.5339 23.6239 28.6784 24.1866C29.8228 24.7492 30.8225 25.5675 31.6002 26.5783C32.7508 24.5498 33.3492 22.255 33.3357 19.923C33.3223 17.5909 32.6974 15.3032 31.5235 13.2882C30.3496 11.2731 28.6677 9.6012 26.6457 8.4393C24.6236 7.2774 22.3322 6.66616 20.0002 6.66659ZM33.2385 30.1266C33.4474 29.8544 33.6474 29.5755 33.8385 29.2899C35.6862 26.5444 36.6712 23.3093 36.6668 19.9999C36.6668 10.7949 29.2052 3.33325 20.0002 3.33325C10.7952 3.33325 3.33351 10.7949 3.33351 19.9999C3.32826 23.6612 4.53358 27.2215 6.76185 30.1266L6.75351 30.1566L7.34518 30.8449C8.90832 32.6724 10.8492 34.1393 13.0339 35.1443C15.2186 36.1494 17.5953 36.6687 20.0002 36.6666C20.3602 36.6666 20.718 36.6555 21.0735 36.6333C24.0808 36.4436 26.9797 35.4382 29.4585 33.7249C30.644 32.9071 31.7185 31.939 32.6552 30.8449L33.2468 30.1566L33.2385 30.1266ZM20.0002 9.99992C18.6741 9.99992 17.4023 10.5267 16.4646 11.4644C15.527 12.4021 15.0002 13.6738 15.0002 14.9999C15.0002 16.326 15.527 17.5978 16.4646 18.5355C17.4023 19.4731 18.6741 19.9999 20.0002 19.9999C21.3263 19.9999 22.598 19.4731 23.5357 18.5355C24.4734 17.5978 25.0002 16.326 25.0002 14.9999C25.0002 13.6738 24.4734 12.4021 23.5357 11.4644C22.598 10.5267 21.3263 9.99992 20.0002 9.99992Z"
        fill="#359DC5"
      />
    </svg>
  );
};

export default ParentsStep1Icon;
