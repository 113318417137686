const CurlyArrowDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="46"
      viewBox="0 0 116 46"
      fill="none"
    >
      <path
        d="M109.225 18.5673C109.259 16.7512 109.257 14.8717 109.385 12.9829C109.474 11.9213 109.591 10.7918 109.983 9.80749C110.512 8.45058 111.707 7.6396 113.087 8.03545C113.997 8.30013 114.948 9.15536 115.403 9.98018C115.898 10.9011 116.015 12.0798 115.994 13.1695C115.936 19.7358 115.944 26.2974 115.657 32.8801C115.524 36.5499 112.902 38.6172 109.438 37.6779C106.309 36.8136 103.279 35.4807 100.34 34.0422C96.0352 31.9431 91.8425 29.5721 87.6217 27.2691C87.2795 27.0958 87.1107 26.5802 86.8178 26.1725C87.818 23.1002 90.3113 22.46 93.0766 22.8557C94.9771 23.1484 96.8496 23.9706 98.6518 24.732C100.421 25.4957 102.109 26.4961 103.719 27.3372C104.996 26.2895 104.152 25.5585 103.711 24.9305C98.4681 17.5564 91.0786 12.9081 83.0917 9.12697C81.5543 8.37962 79.7217 8.11504 77.9969 7.97467C58.6353 6.52443 40.5188 10.492 23.903 20.6835C16.7656 25.0855 12.0585 31.6547 8.39677 39.0066C7.73141 40.3073 7.78323 41.9524 7.42723 43.4288C7.1017 44.87 6.06835 45.6365 4.66231 45.803C3.02898 46.0189 1.36036 45.2809 0.86336 43.8656C0.446058 42.6424 0.117781 41.2808 0.293366 40.0482C0.501699 38.3515 0.967794 36.5704 1.74794 35.0307C6.18045 26.0079 12.1903 18.2902 21.0946 13.2342C33.2303 6.33113 46.1536 1.67997 60.2023 0.77346C66.7662 0.336673 73.2925 -0.163395 79.8871 0.749626C83.885 1.32087 87.541 2.64209 90.8924 4.77649C96.66 8.41971 102.212 12.2762 106.708 17.4944C107.136 17.9584 107.692 18.3473 108.212 18.7058C108.35 18.7948 108.639 18.6752 109.225 18.5673Z"
        fill="#F9DE9A"
      />
    </svg>
  );
};

export default CurlyArrowDownIcon;
