import { FC } from 'react';
import { BenefitsListProps } from '@kronoss/bricks/HomePage/BenefitsList/BenefitsList.props';
import styles from './BenefitsList.module.scss';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

const BenefitsList: FC<BenefitsListProps> = () => {
  const { t } = useTranslation('home');
  const benefitsList = [
    {
      id: 1,
      title: (
        <Trans
          i18nKey="home:benefitsList.scheduling.title"
          components={{ strong: <strong /> }}
        />
      ),
      subtitle: t('benefitsList.scheduling.subtitle'),
    },
    {
      id: 2,
      title: (
        <Trans
          i18nKey="home:benefitsList.payroll.title"
          components={{ strong: <strong /> }}
        />
      ),
      subtitle: t('benefitsList.payroll.subtitle'),
    },
    {
      id: 3,
      title: (
        <Trans
          i18nKey="home:benefitsList.adminWorkload.title"
          components={{ strong: <strong /> }}
        />
      ),
      subtitle: t('benefitsList.adminWorkload.subtitle'),
    },
    {
      id: 4,
      title: (
        <Trans
          i18nKey="home:benefitsList.centralizedManagement.title"
          components={{ strong: <strong /> }}
        />
      ),
      subtitle: t('benefitsList.centralizedManagement.subtitle'),
    },
    {
      id: 5,
      title: (
        <Trans
          i18nKey="home:benefitsList.scheduling.title"
          components={{ strong: <strong /> }}
        />
      ),
      subtitle: t('benefitsList.manageAnytime.subtitle'),
    },
  ];
  return (
    <Container>
      <Grid container spacing={6} className={styles.BenefitsList}>
        {benefitsList.map((benefit) => (
          <Grid size={{ md: 'grow', xs: 12 }} key={benefit.id}>
            <div className={styles.BenefitsItem}>
              <div className={styles.Title}>{benefit.title}</div>
              <div className={styles.Subtitle}>{benefit.subtitle}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BenefitsList;
