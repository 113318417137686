const GermanyIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_8269)">
        <mask
          id="mask0_172_8269"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="#D80027"
          />
        </mask>
        <g mask="url(#mask0_172_8269)">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="#D80027"
          />
          <path d="M32 11V0L0 0V11L32 11Z" fill="black" />
          <path d="M32 32V21L0 21V32H32Z" fill="#FFDA44" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_172_8269">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GermanyIcon;
