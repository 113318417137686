const TeachersStep2Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_181_29874)">
        <path
          d="M27.4998 28.75L32.2665 31.5667L31.0165 33.6L24.9998 30V21.6667H27.4998V28.75ZM38.3332 28.3334C38.3332 34.7834 33.1165 40 26.6665 40C23.4832 40 20.6165 38.7334 18.5165 36.6667H9.99984C8.14984 36.6667 6.6665 35.1834 6.6665 33.3334V6.66671C6.6665 5.78265 7.01769 4.93481 7.64281 4.30968C8.26794 3.68456 9.11578 3.33337 9.99984 3.33337H11.6665V15L15.8332 12.5L19.9998 15V3.33337H29.9998C30.8839 3.33337 31.7317 3.68456 32.3569 4.30968C32.982 4.93481 33.3332 5.78265 33.3332 6.66671V18.7667C36.3498 20.8834 38.3332 24.3667 38.3332 28.3334ZM34.9998 28.3334C34.9998 23.7334 31.2665 20 26.6665 20C22.0665 20 18.3332 23.7334 18.3332 28.3334C18.3332 32.9334 22.0665 36.6667 26.6665 36.6667C31.2665 36.6667 34.9998 32.9334 34.9998 28.3334Z"
          fill="#D52F6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_181_29874">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeachersStep2Icon;
