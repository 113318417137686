import { BenefitsCardProps } from '@kronoss/bricks/HomePage/Benefits/components/BenefitsCard/BenefitsCard.props';
import { FC } from 'react';
import styles from './BenefitsCard.module.scss';
import Grid from '@mui/material/Grid2';
import CheckCircleIcon from '@kronoss/icons/CheckCircleIcon';
import Image from 'next/image';
import cn from 'classnames';

const BenefitsCard: FC<BenefitsCardProps> = ({ benefitCard, revert }) => {
  const half = Math.ceil(benefitCard.benefits.length / 2);
  const leftColumn = benefitCard.benefits.slice(0, half);
  const rightColumn = benefitCard.benefits.slice(half);
  return (
    <div className={cn(styles.BenefitsCard, revert && styles.Revert)}>
      <div className={styles.Content}>
        <div className={styles.Purpose}>{benefitCard.purpose}</div>
        <div className={styles.Title}>{benefitCard.title}</div>
        <div className={styles.Subtitle}>{benefitCard.subtitle}</div>
        <div className={styles.BenefitsListWrapper}>
          <Grid container spacing={2}>
            <Grid size={{ md: 6, xs: 12 }}>
              <div className={styles.BenefitsList}>
                {leftColumn.map((benefit, index) => (
                  <div key={index} className={styles.BenefitItem}>
                    <CheckCircleIcon />
                    <div>{benefit}</div>
                  </div>
                ))}
              </div>
            </Grid>

            <Grid size={{ md: 6, xs: 12 }}>
              <div className={styles.BenefitsList}>
                {rightColumn.map((benefit, index) => (
                  <div key={index} className={styles.BenefitItem}>
                    <CheckCircleIcon />
                    <div>{benefit}</div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={styles.Illustration}>
        <Image
          src={benefitCard.illustration}
          alt={'Illustration'}
          width={495}
          height={426}
          // layout={'responsive'}
        />
      </div>
    </div>
  );
};
export default BenefitsCard;
