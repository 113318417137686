const ParentsStep2Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 14V23.134C31.8917 22.1255 29.5276 21.7785 27.2184 22.1387C24.9092 22.4989 22.763 23.5493 21.062 25.152C20.182 25.963 19.4403 26.9121 18.866 27.962C18.362 28.902 18 29.966 18 31C18 31.968 18.312 33.002 18.84 34H11C9.67392 34 8.40215 33.4732 7.46447 32.5355C6.52678 31.5979 6 30.3261 6 29V14H34ZM29 6C30.3261 6 31.5979 6.52678 32.5355 7.46447C33.4732 8.40215 34 9.67392 34 11V12H6V11C6 9.67392 6.52678 8.40215 7.46447 7.46447C8.40215 6.52678 9.67392 6 11 6H29ZM29 24C23.472 24 20 28.636 20 31C20 33.4 23.478 38 29 38C34.522 38 38 33.364 38 31C38 28.6 34.522 24 29 24ZM29 36C27.6739 36 26.4021 35.4732 25.4645 34.5355C24.5268 33.5979 24 32.3261 24 31C24 29.6739 24.5268 28.4021 25.4645 27.4645C26.4021 26.5268 27.6739 26 29 26C30.3261 26 31.5979 26.5268 32.5355 27.4645C33.4732 28.4021 34 29.6739 34 31C34 32.3261 33.4732 33.5979 32.5355 34.5355C31.5979 35.4732 30.3261 36 29 36ZM32 31C32 31.7956 31.6839 32.5587 31.1213 33.1213C30.5587 33.6839 29.7956 34 29 34C28.2044 34 27.4413 33.6839 26.8787 33.1213C26.3161 32.5587 26 31.7956 26 31C26 30.2044 26.3161 29.4413 26.8787 28.8787C27.4413 28.3161 28.2044 28 29 28C29.7956 28 30.5587 28.3161 31.1213 28.8787C31.6839 29.4413 32 30.2044 32 31Z"
        fill="#D52F6D"
      />
    </svg>
  );
};

export default ParentsStep2Icon;
