import { FC, useState } from 'react';
import { FAQProps } from '@kronoss/bricks/FAQ/FAQ.props';
import { Container } from '@mui/material';
import styles from './FAQ.module.scss';
import FaqItem from '@kronoss/bricks/FAQ/components/FaqItem/FaqItem';
import useTranslation from 'next-translate/useTranslation';

export interface SingleFaqProps {
  id: number;
  question: string;
  answer: string;
}

const FAQ: FC<FAQProps> = () => {
  const { t } = useTranslation('home');
  const faqArr: SingleFaqProps[] = [
    {
      id: 1,
      question: t('faq.questions.question1.question'),
      answer: t('faq.questions.question1.answer'),
    },
    {
      id: 2,
      question: t('faq.questions.question2.question'),
      answer: t('faq.questions.question2.answer'),
    },
    {
      id: 3,
      question: t('faq.questions.question3.question'),
      answer: t('faq.questions.question3.answer'),
    },
    {
      id: 4,
      question: t('faq.questions.question4.question'),
      answer: t('faq.questions.question4.answer'),
    },
  ];

  const [isOpen, setIsOpen] = useState<null | number>(null);

  const handleOpenDropdown = (index: number) => {
    if (index === isOpen) {
      setIsOpen(null);
    } else {
      setIsOpen(index);
    }
  };

  return (
    <Container>
      <div className={styles.FAQ} id={'faq'}>
        <div className={styles.Title}>{t('faq.title')}</div>
        <div className={styles.FAQItems}>
          {faqArr &&
            faqArr.length > 0 &&
            faqArr.map((faq) => (
              <FaqItem
                key={faq.id}
                faqItem={faq}
                handleOpenDropdown={handleOpenDropdown}
                active={isOpen}
              />
            ))}
        </div>
      </div>
    </Container>
  );
};

export default FAQ;
