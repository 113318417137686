import { FC, useEffect, useState } from 'react';
import { HeroProps } from '@kronoss/bricks/HomePage/Hero/Hero.props';
import { Container } from '@mui/material';
import styles from './Hero.module.scss';
import SubscribeInput from '@kronoss/components/SubscribeInput/SubscribeInput';
import Image from 'next/image';
import UnitedKingdomIcon from '@kronoss/icons/countries/UnitedKingdomIcon';
import SpainIcon from '@kronoss/icons/countries/SpainIcon';
import PortugalIcon from '@kronoss/icons/countries/PortugalIcon';
import ItalyIcon from '@kronoss/icons/countries/ItalyIcon';
import GermanyIcon from '@kronoss/icons/countries/GermanyIcon';
import FranceIcon from '@kronoss/icons/countries/FranceIcon';
import RussiaIcon from '@kronoss/icons/countries/RussiaIcon';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import Grid from '@mui/material/Grid2';

const Hero: FC<HeroProps> = () => {
  const { t } = useTranslation('home');
  const languages = [
    {
      name: t('studiedLanguages.english'),
      teachersCount: 212,
      icon: <UnitedKingdomIcon />,
    },
    {
      name: t('studiedLanguages.spanish'),
      teachersCount: 340,
      icon: <SpainIcon />,
    },
    {
      name: t('studiedLanguages.portuguese'),
      teachersCount: 120,
      icon: <PortugalIcon />,
    },
    {
      name: t('studiedLanguages.italian'),
      teachersCount: 93,
      icon: <ItalyIcon />,
    },
    {
      name: t('studiedLanguages.german'),
      teachersCount: 78,
      icon: <GermanyIcon />,
    },
    {
      name: t('studiedLanguages.french'),
      teachersCount: 97,
      icon: <FranceIcon />,
    },
    {
      name: t('studiedLanguages.russian'),
      teachersCount: 54,
      icon: <RussiaIcon />,
    },
  ];

  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Handle resizing for mobile layout
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 1023);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={styles.HeroWrapper}>
      <Container>
        <div className={styles.Hero}>
          <div>
            <h1 className={styles.Title}>
              <Trans i18nKey="home:hero.title" components={{ br: <br /> }} />
            </h1>
            <h2 className={styles.Subtitle}>
              <Trans i18nKey="home:hero.subtitle" components={{ br: <br /> }} />
            </h2>
            <p className={styles.SubscribeLabel}>
              {t('hero.subscribeForm.title')}
            </p>
            <SubscribeInput />
          </div>
          <div className={styles.HeroImageWrapper}>
            <Image
              src={'/images/hero.png'}
              alt={'Hero'}
              height={515}
              width={515}
              quality={100}
            />
          </div>
          {!isMobile && (
            <Grid container spacing={2} className={styles.StudiedLanguages}>
              {languages.map((language) => (
                <Grid size={{ md: 'grow', xs: 6, sm: 4 }} key={language.name}>
                  <div className={styles.Language}>
                    <div className={styles.Icon}>{language.icon}</div>
                    <div className={styles.Description}>
                      <div className={styles.Name}>{language.name}</div>
                      <div className={styles.TeachersCount}>
                        {language.teachersCount}{' '}
                        {t('studiedLanguages.teachersCountLabel')}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {isMobile && (
          <Grid container spacing={2} className={styles.StudiedLanguages}>
            {languages.map((language) => (
              <Grid size={{ md: 'grow', xs: 6, sm: 4 }} key={language.name}>
                <div className={styles.Language}>
                  <div className={styles.Icon}>{language.icon}</div>
                  <div className={styles.Description}>
                    <div className={styles.Name}>{language.name}</div>
                    <div className={styles.TeachersCount}>
                      {language.teachersCount}{' '}
                      {t('studiedLanguages.teachersCountLabel')}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default Hero;
