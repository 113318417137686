const CorporateStep1Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 19.9999H24.9998C25.4721 19.9999 25.8682 19.8399 26.1882 19.5199C26.5082 19.1999 26.6676 18.8044 26.6665 18.3333C26.6654 17.8621 26.5054 17.4666 26.1865 17.1466C25.8676 16.8266 25.4721 16.6666 24.9998 16.6666H14.9998C14.5276 16.6666 14.1321 16.8266 13.8132 17.1466C13.4943 17.4666 13.3343 17.8621 13.3332 18.3333C13.3321 18.8044 13.4921 19.2005 13.8132 19.5216C14.1343 19.8427 14.5298 20.0021 14.9998 19.9999ZM14.9998 13.3333H24.9998C25.4721 13.3333 25.8682 13.1733 26.1882 12.8533C26.5082 12.5333 26.6676 12.1377 26.6665 11.6666C26.6654 11.1955 26.5054 10.7999 26.1865 10.4799C25.8676 10.1599 25.4721 9.99992 24.9998 9.99992H14.9998C14.5276 9.99992 14.1321 10.1599 13.8132 10.4799C13.4943 10.7999 13.3343 11.1955 13.3332 11.6666C13.3321 12.1377 13.4921 12.5338 13.8132 12.8549C14.1343 13.176 14.5298 13.3355 14.9998 13.3333ZM33.2498 34.1249L26.4998 25.3333C26.0276 24.6944 25.4443 24.2016 24.7498 23.8549C24.0554 23.5083 23.3054 23.3344 22.4998 23.3333H6.6665V6.66659C6.6665 5.74992 6.99317 4.96547 7.6465 4.31325C8.29984 3.66103 9.08428 3.33436 9.99984 3.33325H29.9998C30.9165 3.33325 31.7015 3.65992 32.3548 4.31325C33.0082 4.96659 33.3343 5.75103 33.3332 6.66659V33.3333C33.3332 33.4721 33.3265 33.6044 33.3132 33.7299C33.2998 33.8555 33.2787 33.9871 33.2498 34.1249ZM9.99984 36.6666C9.08317 36.6666 8.29873 36.3405 7.6465 35.6883C6.99428 35.036 6.66762 34.251 6.6665 33.3333V26.6666H22.4998C22.7776 26.6666 23.0348 26.7294 23.2715 26.8549C23.5082 26.9805 23.7093 27.1538 23.8748 27.3749L30.8748 36.5416C30.7359 36.5971 30.5904 36.6321 30.4382 36.6466C30.2859 36.661 30.1398 36.6677 29.9998 36.6666H9.99984Z"
        fill="#359DC5"
      />
    </svg>
  );
};

export default CorporateStep1Icon;
