const TeachersStep1Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3333 11.6667C13.3333 9.89856 14.0357 8.20286 15.286 6.95262C16.5362 5.70238 18.2319 5 20 5C21.7681 5 23.4638 5.70238 24.714 6.95262C25.9643 8.20286 26.6667 9.89856 26.6667 11.6667C26.6667 13.4348 25.9643 15.1305 24.714 16.3807C23.4638 17.631 21.7681 18.3333 20 18.3333C18.2319 18.3333 16.5362 17.631 15.286 16.3807C14.0357 15.1305 13.3333 13.4348 13.3333 11.6667ZM13.3333 21.6667C11.1232 21.6667 9.00358 22.5446 7.44078 24.1074C5.87797 25.6702 5 27.7899 5 30C5 31.3261 5.52678 32.5979 6.46447 33.5355C7.40215 34.4732 8.67392 35 10 35H30C31.3261 35 32.5979 34.4732 33.5355 33.5355C34.4732 32.5979 35 31.3261 35 30C35 27.7899 34.122 25.6702 32.5592 24.1074C30.9964 22.5446 28.8768 21.6667 26.6667 21.6667H13.3333Z"
        fill="#359DC5"
      />
    </svg>
  );
};

export default TeachersStep1Icon;
