const FranceIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_8282)">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4182 0 32 3.58172 32 8V24C32 28.4183 28.4182 32 24 32H8.00001C3.58173 32 0 28.4183 0 24V8Z"
          fill="white"
        />
        <mask
          id="mask0_172_8282"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4182 0 32 3.58172 32 8V24C32 28.4183 28.4182 32 24 32H8.00001C3.58173 32 0 28.4183 0 24V8Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_172_8282)">
          <path d="M11 0H0V32H11V0Z" fill="#0052B4" />
          <path d="M32 0H21V32H32V0Z" fill="#D80027" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_172_8282">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FranceIcon;
