const SchoolsStep1Icon = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333496 17V10.3333C0.333496 5.61927 0.333496 3.26225 1.79796 1.79778C3.26243 0.333313 5.61945 0.333313 10.3335 0.333313H23.6668C28.3809 0.333313 30.7379 0.333313 32.2024 1.79778C33.6668 3.26225 33.6668 5.61927 33.6668 10.3333V17C33.6668 21.714 33.6668 24.071 32.2024 25.5355C31.1251 26.6128 29.5648 26.8976 26.9385 26.9729C26.9323 25.7466 26.5063 24.5219 25.6604 23.5292C25.6125 23.4731 25.5836 23.4033 25.5777 23.3298C25.3687 20.7106 23.2889 18.6308 20.6698 18.4218C20.5963 18.4159 20.5264 18.387 20.4703 18.3392C18.4705 16.635 15.5292 16.635 13.5294 18.3392C13.4733 18.387 13.4035 18.4159 13.3299 18.4218C10.7108 18.6308 8.63099 20.7106 8.42199 23.3298C8.41612 23.4033 8.3872 23.4731 8.33936 23.5292C7.49341 24.5219 7.06738 25.7466 7.06127 26.9729C4.43533 26.8976 2.87518 26.6127 1.79796 25.5355C0.333496 24.071 0.333496 21.714 0.333496 17ZM10.7502 6.99998C10.7502 6.30962 11.3098 5.74998 12.0002 5.74998H22.0002C22.6905 5.74998 23.2502 6.30962 23.2502 6.99998C23.2502 7.69034 22.6905 8.24998 22.0002 8.24998H12.0002C11.3098 8.24998 10.7502 7.69034 10.7502 6.99998ZM8.66683 11.5833C7.97647 11.5833 7.41683 12.143 7.41683 12.8333C7.41683 13.5237 7.97647 14.0833 8.66683 14.0833H25.3335C26.0239 14.0833 26.5835 13.5237 26.5835 12.8333C26.5835 12.143 26.0239 11.5833 25.3335 11.5833H8.66683Z"
        fill="#359DC5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8488 20.242C17.7834 19.334 16.2164 19.334 15.151 20.242C14.6944 20.631 14.1268 20.8662 13.5288 20.9139C12.1334 21.0252 11.0254 22.1333 10.9141 23.5286C10.8663 24.1266 10.6312 24.6942 10.2421 25.1508C9.33421 26.2162 9.33421 27.7832 10.2421 28.8486C10.6312 29.3051 10.8663 29.8728 10.9141 30.4707C11.0254 31.8661 12.1334 32.9741 13.5288 33.0855C14.1268 33.1332 14.6944 33.3683 15.151 33.7574C16.2164 34.6653 17.7834 34.6653 18.8488 33.7574C19.3053 33.3683 19.873 33.1332 20.4709 33.0855C21.8663 32.9741 22.9743 31.8661 23.0857 30.4707C23.1334 29.8728 23.3685 29.3051 23.7576 28.8486C24.6655 27.7832 24.6655 26.2162 23.7576 25.1508C23.3685 24.6942 23.1334 24.1266 23.0857 23.5286C22.9743 22.1333 21.8663 21.0252 20.4709 20.9139C19.873 20.8662 19.3053 20.631 18.8488 20.242ZM20.3531 26.2471C20.8578 25.7761 20.885 24.9851 20.414 24.4804C19.9429 23.9757 19.152 23.9485 18.6473 24.4195L15.9287 26.9568L15.3531 26.4195C14.8484 25.9485 14.0574 25.9757 13.5863 26.4804C13.1153 26.9851 13.1426 27.7761 13.6473 28.2471L15.0758 29.5805C15.5561 30.0287 16.3014 30.0287 16.7816 29.5805L20.3531 26.2471Z"
        fill="#359DC5"
      />
    </svg>
  );
};

export default SchoolsStep1Icon;
