const CheckCircleIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.520996" width="20" height="20" rx="10" fill="currentColor" />
      <path
        d="M5.15137 11.2137L7.92193 13.9843L14.8483 7.05786"
        stroke="white"
        strokeWidth="2.42424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckCircleIcon;
