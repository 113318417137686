import { FC } from 'react';
import { BenefitsProps } from '@kronoss/bricks/HomePage/Benefits/Benefits.props';
import styles from './Benefits.module.scss';
import { Container } from '@mui/material';
import BenefitsCard from '@kronoss/bricks/HomePage/Benefits/components/BenefitsCard/BenefitsCard';
import useTranslation from 'next-translate/useTranslation';

const Benefits: FC<BenefitsProps> = () => {
  const { t } = useTranslation('home');

  const benefits = [
    {
      id: 1,
      purpose: t('benefits.cards.forSchools.purpose'),
      title: t('benefits.cards.forSchools.title'),
      subtitle: t('benefits.cards.forSchools.subtitle'),
      illustration: '/images/schoolBenefits.png',
      benefits: t(
        'benefits.cards.forSchools.benefits',
        {},
        { returnObjects: true },
      ) as string[],
    },
    {
      id: 2,
      purpose: t('benefits.cards.forStudents.purpose'),
      title: t('benefits.cards.forStudents.title'),
      subtitle: t('benefits.cards.forStudents.subtitle'),
      illustration: '/images/studentBenefits.png',
      benefits: t(
        'benefits.cards.forStudents.benefits',
        {},
        { returnObjects: true },
      ) as string[],
    },
  ];
  return (
    <Container>
      <div className={styles.Benefits} id={'benefits'}>
        <div className={styles.TitleWrapper}>
          <div className={styles.Title}>{t('benefits.title')}</div>
          <div className={styles.Subtitle}>{t('benefits.subtitle')}</div>
        </div>
        <div className={styles.BenefitsWrapper}>
          {benefits.map((benefit) => (
            <BenefitsCard
              benefitCard={benefit}
              key={benefit.id}
              revert={benefit.id % 2 == 0}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Benefits;
