const TeachersStep3Icon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.1719 1.58594L24.6563 5.91016L28.7891 8.51562C21.4062 17.8125 10.7266 23.5391 1.70312 25.7188L4.25938 30.375C14.3984 27.0703 25.1328 21.1094 32.0469 10.5781L35.1953 12.5625L36.1719 1.58594ZM36.7734 10.7031L36.3984 14.9844L32.3906 12.4531C32.25 12.6641 32.1016 12.8672 31.9531 13.0781V38.0469H38.0469V10.7031H36.7734ZM25.0078 20.7031C24.0156 21.5625 22.9922 22.375 21.9531 23.1406V38.0469H28.0469V20.7031H25.0078ZM15.3437 27.2656C14.2187 27.8594 13.0938 28.4063 11.9531 28.9219V38.0469H18.0469V27.2656H15.3437ZM1.95313 30.7031V38.0469H8.04688V30.7031H7.60313C6.44531 31.1328 5.28828 31.5312 4.13828 31.8984L3.58672 32.0703L2.83672 30.7031H1.95313Z"
        fill="#2A5FAC"
      />
    </svg>
  );
};

export default TeachersStep3Icon;
