const UnitedKingdomIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_172_7935)">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill="white"
        />
        <mask
          id="mask0_172_7935"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_172_7935)">
          <path
            d="M0.134366 3.82373C-1.43665 5.86772 -2.62127 8.22358 -3.31104 10.7829H7.09352L0.134366 3.82373Z"
            fill="#012169"
          />
          <path
            d="M35.3111 10.7828C34.6213 8.22358 33.4366 5.86772 31.8657 3.82373L24.9067 10.7828H35.3111Z"
            fill="#012169"
          />
          <path
            d="M-3.31104 21.2175C-2.62119 23.7768 -1.43657 26.1326 0.134366 28.1765L7.09328 21.2175H-3.31104Z"
            fill="#012169"
          />
          <path
            d="M28.1763 0.134447C26.1324 -1.43658 23.7766 -2.62119 21.2173 -3.31104V7.09344L28.1763 0.134447Z"
            fill="#012169"
          />
          <path
            d="M3.82373 31.8654C5.86772 33.4364 8.22358 34.621 10.7828 35.3109V24.9065L3.82373 31.8654Z"
            fill="#012169"
          />
          <path
            d="M10.7827 -3.31104C8.2235 -2.62119 5.86764 -1.43658 3.82373 0.134368L10.7827 7.09336V-3.31104Z"
            fill="#012169"
          />
          <path
            d="M21.2173 35.3109C23.7765 34.621 26.1324 33.4364 28.1763 31.8655L21.2173 24.9065V35.3109Z"
            fill="#012169"
          />
          <path
            d="M24.9067 21.2175L31.8657 28.1766C33.4366 26.1327 34.6213 23.7768 35.3111 21.2175H24.9067Z"
            fill="#012169"
          />
          <path
            d="M35.8308 13.3914H18.6089L18.6088 -3.8307C17.7548 -3.94187 16.8842 -4 16 -4C15.1157 -4 14.2453 -3.94187 13.3914 -3.8307V13.3912L-3.8307 13.3913C-3.94187 14.2453 -4 15.1159 -4 16C-4 16.8843 -3.94187 17.7548 -3.8307 18.6087H13.3912L13.3913 35.8308C14.2453 35.942 15.1157 36.0001 16 36.0001C16.8842 36.0001 17.7548 35.942 18.6087 35.8308V18.6089L35.8308 18.6088C35.942 17.7548 36.0001 16.8843 36.0001 16C36.0001 15.1159 35.942 14.2453 35.8308 13.3914Z"
            fill="#C8102E"
          />
          <path
            d="M21.2173 21.2174L30.142 30.1421C30.5525 29.7318 30.944 29.3028 31.3176 28.8581L23.6768 21.2173L21.2173 21.2174Z"
            fill="#C8102E"
          />
          <path
            d="M10.7827 21.2175L1.85791 30.1422C2.26822 30.5526 2.6972 30.9442 3.14189 31.3178L10.7827 23.6768V21.2175Z"
            fill="#C8102E"
          />
          <path
            d="M10.7825 10.7826L1.85776 1.85767C1.44729 2.26798 1.05573 2.69696 0.682129 3.14165L8.32301 10.7825L10.7825 10.7826Z"
            fill="#C8102E"
          />
          <path
            d="M21.2173 10.7825L30.1421 1.85768C29.7318 1.44721 29.3028 1.05565 28.8581 0.682129L21.2173 8.32301V10.7825Z"
            fill="#C8102E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_172_7935">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnitedKingdomIcon;
