const CorporateStep2Icon = () => {
  return (
    <svg
      width="35"
      height="40"
      viewBox="0 0 35 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 0C4.06875 0 3.80625 0.05 3.54375 0.15C1.8375 0.55 0.48125 2.1 0.13125 4.05C0 4.35 0 4.65 0 5V32.5C0 36.65 2.93125 40 6.5625 40H30.625V35H6.5625C5.3375 35 4.375 33.9 4.375 32.5C4.375 31.1 5.3375 30 6.5625 30H30.625V2.5C30.625 1.1 29.6625 0 28.4375 0H26.25V15L21.875 10L17.5 15V0H4.375Z"
        fill="#D52F6D"
      />
    </svg>
  );
};

export default CorporateStep2Icon;
