import { FC } from 'react';
import cn from 'classnames';
import styles from './FaqItem.module.scss';
import { FaqItemProps } from '@kronoss/bricks/FAQ/components/FaqItem/FaqItem.props';
import PlusIcon from '@kronoss/icons/PlusIcon';

const FaqItem: FC<FaqItemProps> = ({ handleOpenDropdown, active, faqItem }) => {
  return (
    <div
      className={cn(
        styles.DropdownWrapper,
        active === faqItem.id && styles.Open,
      )}
    >
      <div
        className={cn(styles.Dropdown)}
        onClick={() => {
          handleOpenDropdown(faqItem.id);
        }}
      >
        <div>{faqItem.question}</div>
        <div className={styles.DropdownIconWrapper}>
          <PlusIcon />
        </div>
      </div>
      <div className={styles.DropdownItem}>{faqItem.answer}</div>
    </div>
  );
};

export default FaqItem;
