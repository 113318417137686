
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import Layout from '@kronoss/bricks/Layout/Layout';
import Hero from '@kronoss/bricks/HomePage/Hero/Hero';
import HowWorks from '@kronoss/bricks/HomePage/HowWorks/HowWorks';
import BenefitsList from '@kronoss/bricks/HomePage/BenefitsList/BenefitsList';
import Benefits from '@kronoss/bricks/HomePage/Benefits/Benefits';
import FAQ from '@kronoss/bricks/FAQ/FAQ';
export default function Home() {
    const { t } = useTranslation('metainfo');
    return (<>
      <Head>
        <title>{t('kronossContent.title')}</title>
        <link rel="alternate" hrefLang="en" href={`https://kronoss.education/en`}/>
        <link rel="alternate" hrefLang="es" href={`https://kronoss.education/es`}/>
        <link rel="canonical" href={`https://kronoss.education`}/>
      </Head>
      <Layout>
        <Hero />
        <HowWorks />
        <BenefitsList />
        <Benefits />
        <FAQ />
      </Layout>
    </>);
}

    async function __Next_Translate__getStaticProps__1931bb723e6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1931bb723e6__ as getStaticProps }
  