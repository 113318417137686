const SchoolsStep2Icon = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2498 4.16669C13.2498 3.47633 12.6902 2.91669 11.9998 2.91669C11.3095 2.91669 10.7498 3.47633 10.7498 4.16669V6.79878C8.35095 6.99088 6.77612 7.46231 5.61913 8.61931C4.46213 9.77631 3.99069 11.3511 3.7986 13.75H36.8677C36.6756 11.3511 36.2042 9.77631 35.0472 8.61931C33.8902 7.46231 32.3154 6.99088 29.9165 6.79878V4.16669C29.9165 3.47633 29.3569 2.91669 28.6665 2.91669C27.9761 2.91669 27.4165 3.47633 27.4165 4.16669V6.68819C26.3077 6.66669 25.0649 6.66669 23.6665 6.66669H16.9998C15.6014 6.66669 14.3586 6.66669 13.2498 6.68819V4.16669Z"
        fill="#D52F6D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.6665 20C3.6665 18.6016 3.6665 17.3588 3.68801 16.25H36.9783C36.9998 17.3588 36.9998 18.6016 36.9998 20V23.3334C36.9998 29.6187 36.9998 32.7614 35.0472 34.7141C33.0946 36.6667 29.9519 36.6667 23.6665 36.6667H16.9998C10.7144 36.6667 7.57175 36.6667 5.61913 34.7141C3.6665 32.7614 3.6665 29.6187 3.6665 23.3334V20ZM28.6665 23.3334C29.587 23.3334 30.3332 22.5872 30.3332 21.6667C30.3332 20.7462 29.587 20 28.6665 20C27.746 20 26.9998 20.7462 26.9998 21.6667C26.9998 22.5872 27.746 23.3334 28.6665 23.3334ZM28.6665 30C29.587 30 30.3332 29.2538 30.3332 28.3334C30.3332 27.4129 29.587 26.6667 28.6665 26.6667C27.746 26.6667 26.9998 27.4129 26.9998 28.3334C26.9998 29.2538 27.746 30 28.6665 30ZM21.9998 21.6667C21.9998 22.5872 21.2536 23.3334 20.3332 23.3334C19.4127 23.3334 18.6665 22.5872 18.6665 21.6667C18.6665 20.7462 19.4127 20 20.3332 20C21.2536 20 21.9998 20.7462 21.9998 21.6667ZM21.9998 28.3334C21.9998 29.2538 21.2536 30 20.3332 30C19.4127 30 18.6665 29.2538 18.6665 28.3334C18.6665 27.4129 19.4127 26.6667 20.3332 26.6667C21.2536 26.6667 21.9998 27.4129 21.9998 28.3334ZM11.9998 23.3334C12.9203 23.3334 13.6665 22.5872 13.6665 21.6667C13.6665 20.7462 12.9203 20 11.9998 20C11.0794 20 10.3332 20.7462 10.3332 21.6667C10.3332 22.5872 11.0794 23.3334 11.9998 23.3334ZM11.9998 30C12.9203 30 13.6665 29.2538 13.6665 28.3334C13.6665 27.4129 12.9203 26.6667 11.9998 26.6667C11.0794 26.6667 10.3332 27.4129 10.3332 28.3334C10.3332 29.2538 11.0794 30 11.9998 30Z"
        fill="#D52F6D"
      />
    </svg>
  );
};

export default SchoolsStep2Icon;
